import React from 'react';
import { Navigate } from 'react-router-dom';

const ProtectedRoute = ({ children }) => {
  // Assuming you store the authentication status in localStorage (you can also use state or sessionStorage)
  const isAuthenticated = localStorage.getItem('isAuthenticated');

  // If user is not authenticated, redirect to login page
  if (!isAuthenticated) {
    return <Navigate to="/" />;
  }

  // If authenticated, render the child components (e.g., Dashboard)
  return children;
};

export default ProtectedRoute;
