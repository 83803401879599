import './login.css';
import React, { useState } from 'react';
import axios from 'axios'; 
import { useNavigate } from 'react-router-dom';

function Login() {
  const [accessKey, setAccessKey] = useState(''); 
  const [error, setError] = useState(null); 
  const [success, setSuccess] = useState(false);
  const [isLoading, setIsLoading] = useState(false); 
  
  const navigate = useNavigate();

  const handleInputChange = (e) => {
    setAccessKey(e.target.value);
  };

  const handleSubmit = async () => {
    setIsLoading(true);
    setTimeout(async () => {
      try {
        const response = await axios.post('https://wgpt.bulknode.com/loginauth', {
          accessKey: accessKey,
        });

        if (response.status === 200) {
          setSuccess(true);
          setError(null);
          
          // Store authentication flag in localStorage
          localStorage.setItem('isAuthenticated', 'true');

          // Redirect to the dashboard
          navigate('/dashboard', { state: { accessKey: accessKey } });
        }
      } catch (error) {
        setError('Invalid Access Key. Please try again.');
        setSuccess(false);
        setTimeout(() => {
          setError(null);
        }, 3000);
      } finally {
        setIsLoading(false);
      }
    }, 3000);
  };

  return (
    <div className='parent'>
      <div className="App d-flex justify-content-center align-items-center vh-100">
        <div className="container text-center boxx p-5 rounded shadow-lg">
          <h1 className="mb-4 devil-text fw-bold">AnarchyGPT</h1>
          <p className="mb-4 devil-subtext">Enter your access key to enter the zone</p>

          {/* Input field for Access Key */}
          <div className="mb-4 inputBox">
            <input
              type="password"
              className="form-control form-control-lg border-danger devil-input"
              placeholder="Access Key"
              value={accessKey}
              onChange={handleInputChange} // Update state on input change
              disabled={isLoading} // Disable input during loading
            />
          </div>
          
          {/* Error message if there's an error */}
          {error && <p className="text-danger">{error}</p>}

          {/* Success message if login is successful */}
          {success && <p className="text-success">Login successful!</p>}

          {/* Login button with Bootstrap spinner */}
          <button
            className="btn btn-danger btn-lg devil-button"
            onClick={handleSubmit}
            disabled={isLoading || !accessKey} // Disable button if loading or access key is empty
          >
            {isLoading ? (
              <div className='spinner-border' role='status'>
                <span className="sr-only"></span>
              </div>
            ) : (
              'Login'
            )}
          </button>

          {/* Footer text */}
          <p className="mt-4 footer-text">
            If you haven't access key, contact{' '}
            <span className="worm-father">
              <a href='https://t.me/anarchylord' target='_blank' rel="noreferrer">@anarchylord
</a>
            </span>
          </p>
        </div>
      </div>
    </div>
  );
}

export default Login;
