import React, { useState, useEffect, useRef } from "react";
import axios from "axios";
import { useLocation } from "react-router-dom";
import "./dashboard.css";
import ReactMarkdown from "react-markdown";
import { Prism as SyntaxHighlighter } from "react-syntax-highlighter";
import { coy } from "react-syntax-highlighter/dist/esm/styles/prism";
import remarkGfm from "remark-gfm"; // For GitHub flavored markdown

export default function Dashboard() {
  const location = useLocation();
  const accessKey = location.state?.accessKey;
  const [userName, setUserName] = useState("");
  const [model, setModel] = useState("001");
  const [maxTokens, setMaxTokens] = useState("1024");
  const [inputText, setInputText] = useState("");
  const [responseText, setResponseText] = useState("");
  const [displayedText, setDisplayedText] = useState("");
  const textareaRef = useRef(null);
  const [showOptions, setShowOptions] = useState(false);
  const [waiting, setWaiting] = useState(true);
  const [generating, setGenerating] = useState(false);
  const [modelParam, setModelParam] = useState("7");
  const [planid, setPlanid] = useState(null);
  const [fullAccess, setFullAccess] = useState(false);

  const [buttonText, setButtonText] = useState("Generate");
  const [buttonDisabled, setButtonDisabled] = useState(false);

  useEffect(() => {
    if (accessKey) {
      axios
        .post("https://wgpt.bulknode.com/loaduser", { accessKey })
        .then((response) => {
          console.log("User data loaded successfully:", response.data);
          setUserName(response.data.name);
          setPlanid(response.data.plan);
        })
        .catch((error) => {
          console.error("Error loading user data:", error);
        });

      if (planid === "1") {
        setFullAccess(true);
      }
    }
  }, [accessKey, planid]);

  const handleInputChange = (e) => {
    const text = e.target.value;
    const words = text
      .trim()
      .split(/\s+/)
      .filter((word) => word.length > 0);
    const wordCount = words.length;
    const charCount = text.length;

    setInputText(text);

    if (wordCount > 200) {
      setButtonDisabled(true);
      setButtonText("Too many words");
    } else if (charCount > 10000) {
      setButtonDisabled(true);
      setButtonText("Too many characters");
    } else {
      setButtonDisabled(false);
      setButtonText("Generate");
    }

    autoResizeTextarea();
  };

  const autoResizeTextarea = () => {
    const textarea = textareaRef.current;
    textarea.style.height = "auto"; // Reset height to auto first
    const maxHeight = 5 * 24; // 5 rows * line-height of 24px (adjust if needed)
    textarea.style.height = `${Math.min(textarea.scrollHeight, maxHeight)}px`;
  };

  const handleGenerateClick = async () => {
    if (buttonDisabled || generating) return; // Prevent action if button is disabled

    setGenerating(true);
    setButtonText("Thinking...");

    try {
      const response = await axios.post(
        "https://wgpt.bulknode.com/wormgptrequest",
        {
          inputText,
          accessKey,
          model,
          maxTokens,
          modelParam,
        }
      );
      setWaiting(false);
      setResponseText(response.data.message);
      setGenerating(false);
      animateText(response.data.message);
      setButtonText("Generate");
      console.log("Response:", response.data);
    } catch (error) {
      console.error("Error:", error);
      setGenerating(false);
      setButtonText("Generate");
    }
  };

  const animateText = (text) => {
    let index = 0;
    setDisplayedText("");
    const interval = setInterval(() => {
      if (index < text.length) {
        setDisplayedText((prev) => prev + text[index]);
        index++;
      } else {
        clearInterval(interval);
      }
    }, 3);
  };

  const renderCodeBlock = ({ inline, className, children, ...props }) => {
    const match = /language-(\w+)/.exec(className || "");
    return !inline && match ? (
      <SyntaxHighlighter
        style={coy}
        language={match[1]}
        PreTag="div"
        {...props}
      >
        {String(children).replace(/\n$/, "")}
      </SyntaxHighlighter>
    ) : (
      <code className={className} {...props}>
        {children}
      </code>
    );
  };

  return (
    <div className="container-md dashboard-container">
      <div className="userData d-flex justify-content-end">
        <div className="userName">{userName} &nbsp;</div>
        <div className="plan"></div>
      </div>

      <div className="row justify-content-center">
        <div className="col-lg-8 col-md-10 col-sm-12 text-center">
          <div className="input-group full-width-container">
            <textarea
              className="form-control danger-input auto-resize-textarea"
              placeholder="Ask from anarchy..."
              aria-label="Text Input"
              rows={2}
              value={inputText}
              onChange={handleInputChange}
              ref={textareaRef}
            />

            <div className="container-md checkBox mt-2">
              <label className="form-check-label" htmlFor="showOptions">
                <p>Show options&nbsp;</p>
              </label>
              <input
                type="checkbox"
                className="form-check-input"
                id="showOptions"
                checked={showOptions}
                onChange={() => setShowOptions(!showOptions)}
              />
            </div>
          </div>

          {showOptions && (
            <div className="dropdown-container mt-3">
              <div className="row">
                <div className="col-md-4 mb-3">
                  Mode
                  <select
                    className="form-select danger-select"
                    value={model}
                    onChange={(e) => setModel(e.target.value)}
                  >
                    <option value="001">Anarchy👹</option>
                    <option value="002">HacxGPT</option>
                    <option value="003" disabled={!fullAccess}>
                      CrimeDAN
                    </option>
                  </select>
                </div>
                <div className="col-md-4 mb-3">
                  Max Tokens
                  <select
                    className="form-select danger-select"
                    value={maxTokens}
                    onChange={(e) => setMaxTokens(e.target.value)}
                  >
                    <option value="512">512</option>
                    <option value="1024">1024</option>
                    <option value="2048">2048</option>
                    <option value="4096" disabled={!fullAccess}>
                      4096
                    </option>
                  </select>
                </div>
                <div className="col-md-4 mb-3">
                  Model
                  <select
                    className="form-select danger-select"
                    value={modelParam}
                    onChange={(e) => setModelParam(e.target.value)}
                  >
                    <option value="7">7 Billion</option>
                    <option value="12" disabled={!fullAccess}>
                      12 Billion
                    </option>
                  </select>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>

      <div className="container-md d-flex justify-content-center align-content-center">
        <button
          className="btn btn-danger generate-btn"
          type="button"
          onClick={handleGenerateClick}
          disabled={buttonDisabled || generating}
        >
          {buttonText}
        </button>
      </div>

      {!waiting && (
        <div className="row justify-content-center">
          <div className="col-lg-8 col-md-10 col-sm-12 ">
            <div className="responseText mt-3">
              <ReactMarkdown
                children={displayedText}
                remarkPlugins={[remarkGfm]} // Add GitHub-flavored markdown support
                components={{
                  code: renderCodeBlock, // Use custom code block renderer
                }}
              />
            </div>
          </div>
        </div>
      )}
    </div>
  );
}
